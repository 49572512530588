import plantsImage from "../../assets/images/home-bg.jpg";
import { Colors } from "../../assets/styles/properties";

const styles = {
  homeImage: {
    backgroundImage: `url(${plantsImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: 500,
  },
  homeImageContent: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    width: "100%",
    height: "100%",
    color: "white",
    padding: 32,
    fontSize: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    "@media(max-width: 767px)": {
      padding: 20,
    },
  },
  homeImageText: {
    maxWidth: 600,
  },
  homeImageTitle: {
    marginBottom: 20,
    maxWidth: 400,
    fontSize: 32,
  },
  content: {
    width: "100%",
    padding: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "@media(max-width: 767px)": {
      padding: 20,
    },
  },
  contentText: {
    maxWidth: "700px",
    color: Colors.gray12,
  },
  seagriLogo: {
    height: 200,
  },
};

export default styles;
