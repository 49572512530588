import { Colors } from "../../assets/styles/properties";

const styles = {
  navbar: {
    backgroundColor: "white",
    borderBottom: "1px solid #e8e8e8",
    height: "66px",
    boxShadow: "0 0 10px #f3f1f1",

    "@media(max-width: 767px)": {
      height: "48px",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    float: "left",
    fontSize: 20,
    marginLeft: "30px",

    "@media(max-width: 767px)": {
      marginLeft: "20px",
    },
  },
  logoLink: {
    color: Colors.gray12,

    ":hover": {
      color: Colors.primary,
    },
  },
  content: {
    background: "#fff",
    padding: "24px",
    minHeight: "500px",
  },
  horizontalMenuDiv: {
    float: "right",
    height: "100%",

    "@media(max-width: 767px)": {
      display: "none",
    },
  },
  horizontalMenu: {
    height: "calc(100% + 1px)",
  },
  menuItem: {
    paddingTop: 10,
    height: "calc(100% + 1px)",
  },
  verticalMenuDiv: {
    marginRight: 5,
    float: "right",

    "@media(min-width: 767px)": {
      display: "none",
    },
  },
  menuIcon: {
    fontSize: "20px",
  },
  menuIconBox: {
    marginRight: "-10px",
  },
};

export default styles;
