import { Colors, Sizes } from "../../assets/styles/properties";

const styles = {
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    index: -1,
    backgroundColor: Colors.background,
  },
  formContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "390px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: 20,
    borderRadius: Sizes.containerBorderRadius,
  },
  loginButton: {
    width: "100%",
  },
  registerLink: {
    marginTop: 10,
    justifyContent: "center",
    textAlign: "center",
  },
  title: {
    marginBottom: 5,
  },
};

export default styles;
