/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import Radium from "radium";
import Navbar from "../../components/navbar";
import styles from "./styles";
import logoSeagri from "../../assets/images/logo-seagri.jpg";

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <div style={styles.homeImage}>
        <div style={styles.homeImageContent}>
          <div style={styles.homeImageText}>
            <p style={styles.homeImageTitle}>
              Sejam bem vindos ao Mercado do Agronegócio!
            </p>
            <p>
              O portal é uma iniciativa da SEAGRI para auxiliar o produtor rural
              e as agroindústrias na comercialização de seus produtos,
              utilizando tecnologia e inteligência geográfica.
            </p>
            <p>É a SEAGRI no campo ajudando o produtor.</p>
          </div>
        </div>
      </div>
      <div style={styles.content}>
        <p style={styles.contentText}>
          Inicialmente só o cadastro dos produtores e de produtos são
          permitidos. Em breve teremos uma plataforma completa de
          comercialização do mundo agro.
        </p>
        <div>Desenvolvimento</div>
        <a
          href="http://www.seagri.ba.gov.br/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img style={styles.seagriLogo} src={logoSeagri} alt="seagri" />
        </a>
      </div>
    </div>
  );
};

export default Radium(HomePage);
