import React from "react";
import ReactDOM from "react-dom";
import { StyleRoot } from "radium";
import Routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./assets/styles/index.css";

require("dotenv").config();

ReactDOM.render(
  <StyleRoot>
    <Routes />
  </StyleRoot>,
  document.getElementById("root")
);
