import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Form, Button, Col } from "react-bootstrap";
import Radium from "radium";
import { useHistory, useLocation } from "react-router-dom";
import styles from "../login/styles";
import api from "../../services/api";

const ProductForm = () => {
  const location = useLocation();
  const history = useHistory();

  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    if (location.state === null || location.state === undefined) {
      history.push("/painelprodutor/login");
    } else {
      setIsLogged(true);
    }
  }, [history, location.state]);

  const formik = useFormik({
    initialValues: {
      category: 1,
      product: "",
      quantity: "",
      unit: "",
      price: "",
      note: "",
    },
    validationSchema: Yup.object({
      product: Yup.string().required("Campo obrigatório"),
      quantity: Yup.string().required("Campo obrigatório"),
      price: Yup.string().required("Campo obrigatório"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      api
        .post(
          "/api/products",
          {
            nameProduct: values.product,
            amount: values.quantity,
            idUser: location.state.idUser,
            idType: values.category,
            unit: values.unit,
            price: values.price,
            observation: values.note,
          },
          {
            headers: { auth: location.state.token },
          }
        )
        .then(() => {
          alert("Produto cadastrado!");
          resetForm();
        })
        .catch(() => {
          alert("Houve um erro no cadastro. Verifique os dados!");
        });
      setSubmitting(false);
    },
  });

  if (!isLogged) {
    return null;
  }

  return (
    <div style={styles.background}>
      <Container>
        <Row>
          <Form onSubmit={formik.handleSubmit} style={styles.formContainer}>
            <div style={styles.title} align="center">
              <h4 className="font-weight-normal">Mercado do Agronegócio</h4>
              <div className="font-weight-normal">Cadastro de Produto</div>
            </div>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Tipo de Produto</Form.Label>
                <Form.Control
                  id="category"
                  name="category"
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  as="select"
                  variant="outline-success"
                >
                  <option value="1">Frutas</option>
                  <option value="2">Grãos</option>
                  <option value="3">Fibras</option>
                  <option value="4">Oleaginosos</option>
                  <option value="5">Raízes e Tubérculos</option>
                  <option value="6">Outros Produtos</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Produto</Form.Label>
                <Form.Control
                  id="product"
                  name="product"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.product}
                  type="text"
                  placeholder="Infome o produto"
                />
                {formik.touched.product && formik.errors.product ? (
                  <div>{formik.errors.product}</div>
                ) : null}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  id="quantity"
                  name="quantity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.quantity}
                  type="text"
                  placeholder="número"
                />
                {formik.touched.quantity && formik.errors.quantity ? (
                  <div>{formik.errors.quantity}</div>
                ) : null}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Medida</Form.Label>
                <Form.Control
                  id="unit"
                  name="unit"
                  onChange={formik.handleChange}
                  value={formik.values.unit}
                  as="select"
                  variant="outline-success"
                >
                  <option>quilograma</option>
                  <option>grama</option>
                  <option>litro</option>
                  <option>mililitro</option>
                  <option>unidade</option>
                  <option>dúzia</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Preço</Form.Label>
                <Form.Control
                  id="price"
                  name="price"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.price}
                  type="text"
                  placeholder="Qual o valor?"
                />
                {formik.touched.price && formik.errors.price ? (
                  <div>{formik.errors.price}</div>
                ) : null}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Observação</Form.Label>
                <Form.Control
                  id="note"
                  name="note"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.note}
                  type="text"
                  placeholder="Mensagem?"
                />
                {formik.touched.note && formik.errors.note ? (
                  <div>{formik.errors.note}</div>
                ) : null}
              </Form.Group>
            </Form.Row>

            <div style={styles.buttonsRow}>
              <Button variant="success" type="submit">
                Registrar
              </Button>
            </div>
          </Form>
        </Row>
      </Container>
    </div>
  );
};

export default Radium(ProductForm);
