/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Radium from "radium";
import SubMenu from "antd/lib/menu/SubMenu";
import styles from "./styles";

const Navbar = () => {
  return (
    <nav style={styles.navbar}>
      <div style={styles.logo}>
        <a style={styles.logoLink} href="/">
          Mercado do Agronegócio
        </a>
      </div>
      <div style={styles.horizontalMenuDiv}>
        <Menu
          style={styles.horizontalMenu}
          mode="horizontal"
          defaultSelectedKeys={["0"]}
        >
          <Menu.Item style={styles.menuItem} disabled key="1">
            Mapa
          </Menu.Item>
          <Menu.Item style={styles.menuItem} key="2">
            <a
              href="/painelprodutor/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Painel do Produtor
            </a>
          </Menu.Item>
        </Menu>
      </div>
      <div style={styles.verticalMenuDiv}>
        <Menu mode="horizontal">
          <SubMenu
            key="menuButton"
            title={
              <div style={styles.menuIconBox}>
                <MenuOutlined style={styles.menuIcon} />
              </div>
            }
          >
            <Menu.Item disabled key="1">
              Mapa
            </Menu.Item>
            <Menu.Item key="2">
              <a
                href="/painelprodutor/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Painel do Produtor
              </a>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </nav>
  );
};

export default Radium(Navbar);
