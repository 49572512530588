import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Button, Col } from "react-bootstrap";
import Radium from "radium";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import api from "../../services/api";

const RegisterForm = () => {
  const history = useHistory();

  const changePage = () => {
    history.push("/painelprodutor/login");
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      cpfcnpj: "",
      email: "",
      phone1: "",
      phone2: "",
      city: "",
      cep: "",
      address: "",
      password1: "",
      password2: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .max(60, "Excede a quantidade de letras")
        .required("Campo obrigatório"),
      cpfcnpj: Yup.number().required("Campo obrigatório"),
      email: Yup.string()
        .email("Endereço de e-mail inválido")
        .required("Campo obrigatório"),
      phone1: Yup.string()
        .max(11, "Excede a quantidade de números")
        .required("Campo obrigatório"),
      phone2: Yup.string().max(11, "Excede a quantidade de números"),
      city: Yup.string()
        .max(30, "Excede a quantidade de letras")
        .required("Campo obrigatório"),
      cep: Yup.number().required("Campo obrigatório"),
      address: Yup.string()
        .max(60, "Excede a quantidade de letras")
        .required("Campo obrigatório"),
      password1: Yup.string().required("Campo obrigatório"),
      password2: Yup.string()
        .required("Campo obrigatório")
        .test("passwords-match", "Senha inválida", function (value) {
          return this.parent.password1 === value;
        }),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      api
        .post("/api/users", {
          nameUser: values.fullName,
          email: values.email,
          localization: `${values.address}#@${values.city}#@${values.cep}`,
          password: values.password1,
          cpf: values.cpfcnpj,
          cnpj: values.cpfcnpj,
          phone: values.phone1,
          secondPhone: values.phone2,
        })
        .then(() => {
          alert("Cadastro efetuado com sucesso!");
          changePage();
        })
        .catch((erro) => {
          console.log(erro);
          alert("Houve um erro no cadastro. Verifique seus dados");
        });
      setSubmitting(false);
    },
  });

  return (
    <div style={styles.background}>
      <div style={styles.formContainer}>
        <div style={styles.title} align="center">
          <h4 className="font-weight-normal">Mercado do Agronegócio</h4>
          <div className="font-weight-normal">Cadastro</div>
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Label>Nome Completo</Form.Label>
            <Form.Control
              id="fullName"
              name="fullName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
              type="text"
              placeholder="Insira seu nome"
            />
            {formik.touched.fullName && formik.errors.fullName ? (
              <div>{formik.errors.fullName}</div>
            ) : null}
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>CPF / CNPJ</Form.Label>
              <Form.Control
                id="cpfcnpj"
                name="cpfcnpj"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cpfcnpj}
                type="number"
                placeholder="Insira seu CPF ou CNPJ"
              />
              {formik.touched.cpfcnpj && formik.errors.cpfcnpj ? (
                <div>{formik.errors.cpfcnpj}</div>
              ) : null}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                type="email"
                placeholder="Insira seu e-mail"
              />
              {formik.touched.email && formik.errors.email ? (
                <div>{formik.errors.email}</div>
              ) : null}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Telefone 1</Form.Label>
              <Form.Control
                id="phone1"
                name="phone1"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone1}
                type="number"
                placeholder="Insira seu telefone"
              />
              {formik.touched.phone1 && formik.errors.phone1 ? (
                <div>{formik.errors.phone1}</div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Telefone 2</Form.Label>
              <Form.Control
                id="phone2"
                name="phone2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone2}
                type="number"
                placeholder="Insira seu telefone"
              />
              {formik.touched.phone2 && formik.errors.phone2 ? (
                <div>{formik.errors.phone2}</div>
              ) : null}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                id="city"
                name="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                type="text"
                placeholder="Informe sua cidade"
              />
              {formik.touched.city && formik.errors.city ? (
                <div>{formik.errors.city}</div>
              ) : null}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>CEP</Form.Label>
              <Form.Control
                id="cep"
                name="cep"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cep}
                type="number"
                placeholder="E o seu CEP"
              />
              {formik.touched.cep && formik.errors.cep ? (
                <div>{formik.errors.cep}</div>
              ) : null}
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Endereço</Form.Label>
            <Form.Control
              id="address"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              type="text"
              placeholder="Insira seu endereço"
            />
            {formik.touched.address && formik.errors.address ? (
              <div>{formik.errors.address}</div>
            ) : null}
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Defina uma senha</Form.Label>
              <Form.Control
                id="password1"
                name="password1"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password1}
                type="password"
                placeholder="Digite uma senha"
              />
              {formik.touched.password1 && formik.errors.password1 ? (
                <div>{formik.errors.password1}</div>
              ) : null}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Repita a senha</Form.Label>
              <Form.Control
                id="password2"
                name="password2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password2}
                type="password"
                placeholder="Confirme sua senha"
              />
              {formik.touched.password2 && formik.errors.password2 ? (
                <div>{formik.errors.password2}</div>
              ) : null}
            </Form.Group>
          </Form.Row>

          <div style={styles.buttonsRow}>
            <div style={styles.registerLink}>
              <a href="/painelprodutor/login">Voltar ao login</a>
            </div>
            <Button
              style={styles.registerButton}
              variant="primary"
              type="submit"
            >
              Registrar
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Radium(RegisterForm);
