import React from "react";

import { BrowserRouter, Route } from "react-router-dom";
import HomePage from "./pages/home";
import LoginForm from "./pages/login";
import RegisterForm from "./pages/register";
import ProductForm from "./pages/product";

const Routes = () => (
  <BrowserRouter>
    <Route path="/" exact component={HomePage} />
    <Route path="/painelprodutor/" exact component={ProductForm} />
    <Route path="/painelprodutor/login" exact component={LoginForm} />
    <Route path="/painelprodutor/registro" exact component={RegisterForm} />
  </BrowserRouter>
);

export default Routes;
