import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Button } from "react-bootstrap";
import Radium from "radium";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import api from "../../services/api";

const LoginForm = () => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Endereço de e-mail inválido")
        .required("Campo obrigatório"),
      password: Yup.string().required("Campo obrigatório"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      api
        .post("/api/users/login", {
          email: values.email,
          password: values.password,
        })
        .then((resp) => {
          history.push("/painelprodutor/", {
            idUser: resp.data.auth.response[0].id,
            token: resp.data.token,
          });
        })
        .catch(() => {
          alert("Dados incorretos!");
        });
      setSubmitting(false);
    },
  });

  return (
    <div style={styles.background}>
      <div style={styles.formContainer}>
        <div style={styles.title} align="center">
          <h4 className="font-weight-normal">Mercado do Agronegócio</h4>
          <div className="font-weight-normal">Acesso do Produtor</div>
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              type="email"
              placeholder="Insira seu e-mail"
            />
            {formik.touched.email && formik.errors.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Label>Senha</Form.Label>
            <Form.Control
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              type="password"
              placeholder="Insira sua senha"
            />
            {formik.touched.password && formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
          </Form.Group>
          <Button style={styles.loginButton} type="submit">
            Entrar
          </Button>
        </Form>
        <div style={styles.registerLink}>
          <a href="/painelprodutor/registro">Cadastrar-se</a>
        </div>
      </div>
    </div>
  );
};

export default Radium(LoginForm);
