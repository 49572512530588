import { Colors, Sizes } from "../../assets/styles/properties";

const styles = {
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    index: -1,
    backgroundColor: Colors.background,
  },
  formContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    minWidth: "390px",
    padding: 20,
    borderRadius: Sizes.containerBorderRadius,
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",

    "@media (max-width: 768px)": {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  registerButton: {
    width: "calc(50% - 5px)",

    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  registerLink: {
    marginRight: 10,
    justifyContent: "center",
    textAlign: "center",
    width: "calc(50% - 5px)",

    "@media (max-width: 768px)": {
      marginRight: 0,
      marginTop: 10,
    },
  },
  title: {
    marginBottom: 5,
  },
};

export default styles;
