const Colors = {
  primary: "#1890ff",
  gray12: "#141414",
  gray9: "#434343",
};

const Sizes = {
  containerBorderRadius: "5px",
  mobileWidth: "768px",
};

export { Colors, Sizes };
